import { load, loadMessages } from '@progress/kendo-vue-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/ru/numbers.json';
import currencies from 'cldr-numbers-full/main/ru/currencies.json';
import caGregorian from 'cldr-dates-full/main/ru/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/ru/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/ru/timeZoneNames.json';

import ru from './ru.json';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(ru, 'ru-RU');
